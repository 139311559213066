import ReactDOM from 'react-dom';
import React from 'react';
import { TranslatableComponent } from './Widget';

export interface AnswerType {
  lang: string;
  value: string;
}

function init(Survey: any) {
  if (typeof Survey === 'undefined') {
    return undefined;
  }
  const widget = {
    name: 'dttranslatabletext',
    title: 'dt Translatable text',
    widgetIsLoaded: function () {
      return true;
    },
    isFit: function (question: any) {
      return question.getType() === 'dttranslatabletext';
    },
    isDefaultRender: true,
    activatedByChanged: function () {
      Survey.JsonObject.metaData.addClass(
        'dttranslatabletext',
        [],
        null,
        'empty'
      );

      Survey.Serializer.addProperties('dttranslatabletext', [
        {
          name: 'languageExpression',
          category: 'Expressions',
          default: 'Blanguages',
        },
        {
          name: 'genericText',
          category: 'Locales',
          default: 'Please provide answer in ',
          isLocalizable: true,
        },
        {
          name: 'placeholderText',
          category: 'Locales',
          default: '',
          isLocalizable: true,
        },
        {
          name: 'validationErrorFieldText',
          category: 'Locales',
          default: 'Please provde proper value',
          isLocalizable: true,
        },
        {
          name: 'validationErrorText',
          category: 'Locales',
          default: 'Please provde proper value',
          isLocalizable: true,
        },
        {
          name: 'validationType',
          category: 'Validation',
          default: 'none',
          choices: ['none', 'email', 'url'],
        },
        {
          name: 'inputType',
          category: 'Validation',
          default: 'single',
          choices: ['single', 'multiline', 'editor'],
        },
      ]);
    },
    afterRender: async function (question: any, el: any) {
      const { surveyLocalization } = Survey;
      const { currentLocaleValue, locales } = surveyLocalization;

      const translatedLocales =
        locales[currentLocaleValue === '' ? 'en' : currentLocaleValue];

      ReactDOM.render(
        <TranslatableComponent
          question={question}
          requiredTextEroor={translatedLocales.requiredError}
        />,
        el
      );
    },
  };

  return Survey.CustomWidgetCollection.Instance.addCustomWidget(
    widget,
    'customtype'
  );
}

export default init;
