// eslint-disable-next-line @typescript-eslint/no-var-requires
// const { api, Predicates } = require('prismic.io');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { api, Predicates } = require('prismic.io');

const PRISMIC_REPOSITORY = 'https://deeptrue.cdn.prismic.io/api';

export const insightsQuery = (lang: string) => {
  return api(PRISMIC_REPOSITORY).then((api: any) =>
    api.query(Predicates.at('document.type', 'tool_detail'), {
      lang: lang,
    })
  );
};
