import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { FormikHelpers } from 'formik';
import Cookies from 'js-cookie';
import { navigate } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Briefing from '../components/briefing/briefing';
import api from '../api';
import Section from '../components/section';
import { useAuth, AuthDataTypes } from '../providers/AuthProvider';
import LegalsBriefing, { FormValues } from '../components/Forms/LegalsBriefing';
import { Typography } from '@deeptrue-frontend/ui';
import { insightsQuery } from '../api/prismic';

const ENV =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
const APP_URL =
  ENV === 'development'
    ? 'https://app-dev.deeptrue.com'
    : 'https://app.deeptrue.com';

// eslint-disable-next-line no-var
// declare var gtag;

const BriefingPage = ({
  location,
  pageContext,
  data: { allPrismicBriefing, allPrismicApiResponses },
}: any) => {
  const intl = useIntl();
  const [model, setModel] = useState<any>(null);
  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isVeryfingLegals, setVerifyLegals] = useState(false);
  const [briefingData, setBriefingData] = useState<null | {}>(null);
  const [toolName, setToolName] = useState<null | string>(null);
  const [isToolNameVisible, setToolNameVisible] = useState(true);
  const [insights, setInsights] = useState([]);
  const { briefing } = queryString.parse(location.search);
  const { authData }: { authData: AuthDataTypes } = useAuth();
  const isDevelopment = ENV === 'development';
  const { awConversionId, gaCompleteBriefId } = pageContext;
  const apiResponses = allPrismicApiResponses.nodes[0].data;
  const isAuthenticatedUser = !!authData.user.username;
  const orgCodename = isAuthenticatedUser
    ? authData.user.organizations[0].codename
    : null;

  useEffect(() => {
    if (!briefing) {
      navigate('/');
      return;
    }

    Promise.all([
      api.productsApi.getProductDetails(briefing.toString()),
      insightsQuery(pageContext.prismicLang),
    ]).then(([product, insights]) => {
      const { briefing_template, slug } = product.data;
      const { json } = briefing_template;

      const insight = insights.results.filter(
        (result: any) =>
          result.data['tool_detail.tool_django_slug'].value === briefing
      );

      if (insight.length > 0) {
        setInsights(insight[0].data['tool_detail.body'].value[0].repeat);
      }

      setToolName(product.data.name);

      json.pages.push({
        elements: [
          {
            name: 'name',
            type: 'text',
            hasTooltip: true,
            placeHolder: intl.formatMessage({
              id: 'briefing.fields.placeholder.project-name',
            }),
            tooltip: intl.formatMessage({
              id: 'briefing.fields.tooltip.project-name',
            }),
            title: intl.formatMessage({
              id: 'briefing.fields.title.project-name',
            }),
            isRequired: true,
          },
        ],
      });
      if (!isAuthenticatedUser) {
        json.pages.push({
          elements: [
            {
              name: 'organization_name',
              type: 'text',
              hasTooltip: true,
              placeHolder: intl.formatMessage({
                id: 'briefing.fields.placeholder.organization-name',
              }),
              tooltip: {
                en:
                  'The name of your organization will appear in survey invitation emails if you survey your own contacts.',
                de:
                  'Der Name deiner Organisation erscheint in Einladungs-E-Mails von Umfragen, falls du deine eigenen Kontakte befragst.',
                fr: `Le nom de ton organisation apparaîtra dans les courriels d'invitation à l'enquête si tu interroges tes propres contacts.`,
                it: `Il nome della tua organizzazione apparirà nelle e-mail di invito all'indagine se fai un sondaggio tra i tuoi contatti.`,
              },
              title: intl.formatMessage({
                id: 'briefing.fields.title.organization-name',
              }),
              isRequired: true,
              validators: [
                {
                  type: 'expression',
                  text: {
                    en: 'This name already exists. Choose a unique name.',
                    de:
                      'Dieser Name existiert bereits. Wählen Sie einen eindeutigen Namen.',
                    fr: `Ce nom existe déjà. Choisissez un nom unique.`,
                    it: `Questo nome esiste già. Scegli un nome univoco.`,
                  },
                  expression: 'validateOrganizationName({organization_name})',
                },
              ],
            },
          ],
        });
      }

      json.pages.push({
        elements: [
          {
            name: 'email',
            type: 'text',
            hasTooltip: true,
            placeHolder: intl.formatMessage({
              id: 'briefing.fields.placeholder.email',
            }),
            tooltip: {
              en:
                'This email will be used to create your account on deeptrue. You will receive an email to set your password.',
              de:
                'Diese E-Mail wird verwendet, um den Konto bei deeptrue zu erstellen. Du wirst eine E-Mail erhalten, um dein Passwort festzulegen.',
              fr:
                'Cet e-mail sera utilisé pour créer votre compte deeptrue. Vous recevrez un courriel pour définir votre mot de passe.',
              it:
                'Questa e-mail sarà utilizzata per creare il tuo account deeptrue. Riceverai un’e-mail per impostare la tua password.',
            },
            title: intl.formatMessage({ id: 'briefing.fields.title.email' }),
            isRequired: true,
            validators: [
              {
                type: 'email',
              },
            ],
          },
        ],
      });

      setTemplate(briefing_template.json);
      setIsLoading(false);
    });
  }, []);

  const validateOrganizationName = function (params) {
    api.organization
      .getOrganizationName(params[0])
      .then(() => {
        this.returnResult(true);
      })
      .catch(() => {
        this.returnResult(false);
      });
  };

  const onComplete = ({ data }, model) => {
    const { name, email, organization_name, Bvoucher, ...rest } = data;

    window.history.replaceState(
      '',
      '',
      `?briefing=${briefing}&page=project-creation`
    );
    /* eslint-disable @typescript-eslint/ban-ts-ignore */
    // @ts-ignore
    if (!isDevelopment && awConversionId && gaCompleteBriefId && window.gtag) {
      // @ts-ignore
      window.gtag('event', 'conversion', {
        send_to: `${awConversionId}/${gaCompleteBriefId}`,
      });
      console.info('conversion');
    }
    /* eslint-enable @typescript-eslint/ban-ts-ignore */

    setIsProcessing(true);

    const newProject = {
      json: rest,
      language: intl.locale,
      product_slug: briefing,
      name,
      email,
      organization_name,
      code: Bvoucher ? Bvoucher.code : null,
    };

    if (isAuthenticatedUser) {
      const orgName = authData.user.organizations[0].codename;

      return api.projectApi
        .createProjectWithAuth(newProject, orgName)
        .then(({ data }) => {
          const redirectUrl =
            data.redirect_url || `${APP_URL}/project/${data.id}/review`;

          window.location.replace(redirectUrl);
        });
    }

    setModel(model);
    setBriefingData(newProject);
    return setVerifyLegals(true);
  };

  const createProjectUnauthorizedUser = (
    values: FormValues,
    { setErrors }: FormikHelpers<FormValues>
  ) => {
    setIsProcessing(true);
    setToolNameVisible(false);
    setVerifyLegals(false);
    const { privacy_policy_accepted, terms_agreement } = values;

    return api.projectApi
      .createProject({
        ...briefingData,
        privacy_policy_accepted,
        terms_agreement,
      })
      .then(({ data }) => {
        const isProduction = process.env.NODE_ENV === 'production';
        const { user_created, token, redirect_url } = data;

        if (user_created) {
          Cookies.set('__deeptrueAuth', token, {
            domain: isProduction ? '.deeptrue.com' : 'localhost',
          });
          return window.location.replace(`${redirect_url}project/1/review`);
        }
        window.location.replace(redirect_url);
      })
      .catch(() => {
        setErrors({
          non_field_errors: apiResponses.server_error,
        });
        setIsProcessing(false);
        setVerifyLegals(true);
      });
  };

  const { data: context } = allPrismicBriefing.nodes[0];
  return (
    <Layout lang={intl.locale}>
      <Seo title="Briefing" />
      <Section className="briefing">
        {!isLoading ? (
          isVeryfingLegals ? (
            <>
              {isToolNameVisible && (
                <Typography variant="h6" mt="2rem">
                  {toolName}
                </Typography>
              )}

              <LegalsBriefing
                model={model}
                context={context}
                onSubmit={createProjectUnauthorizedUser}
                initialValues={{
                  terms_agreement: false,
                  privacy_policy_accepted: false,
                }}
                toolName={toolName}
              />
            </>
          ) : (
            <>
              {isToolNameVisible && (
                <Typography variant="h6" mt="2rem" mb="2rem" ml="1rem">
                  {toolName}
                </Typography>
              )}

              <Briefing
                json={template}
                onComplete={onComplete}
                validateOrganizationName={validateOrganizationName}
                isProcessing={isProcessing}
                orgCodename={orgCodename}
                initialValues={{
                  email: authData.user?.email,
                }}
                insights={insights}
              />
            </>
          )
        ) : (
          <div className="m-loader-container">
            <div className="m-loader-container__loader"></div>
            <p className="m-loader-container__loader-info">
              <FormattedMessage id="briefing.loading" />
            </p>
          </div>
        )}
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query Briefing($prismicLang: String) {
    allPrismicApiResponses(filter: { lang: { eq: $prismicLang } }) {
      nodes {
        _previewable
        data {
          server_error
        }
      }
    }
    allPrismicBriefing(filter: { lang: { eq: $prismicLang } }) {
      nodes {
        _previewable
        data {
          terms_and_conditions
          privacy_policy
          legal_terms_desc
          legal_consent
          i_agree_text
          create_project
        }
        lang
      }
    }
  }
`;

export default withPrismicPreview(BriefingPage);
