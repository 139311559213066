/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import validator from 'validator';
import { Editor } from 'react-draft-wysiwyg';
import Draft from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { utils } from '@deeptrue-frontend/ui';
import warningIcon from '../../../../assets/icons/warning.svg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const { translateLanguage } = utils;

interface Props {
  question: {
    languageExpression: string;
    genericText: string;
    data: any;
    value: any;
    name: string;
    placeholderText: string;
    validationType: 'none' | 'email' | 'url';
    validationErrorText: string;
    validationErrorFieldText: string;
    inputType: 'single' | 'multiline' | 'editor';
    survey: any;
    isRequired: boolean;
    requiredErrorText: string;
  };
  requiredTextEroor: string;
}

export const TranslatableComponent = ({
  question,
  requiredTextEroor,
}: Props) => {
  const {
    languageExpression,
    genericText,
    data,
    value,
    name,
    placeholderText,
    validationType,
    validationErrorText,
    validationErrorFieldText,
    inputType,
    survey,
  } = question;

  const validators = {
    none: () => true,
    email: (email: string) => validator.isEmail(email),
    url: (url: string) => validator.isURL(url),
  };

  const formatURL = (url: string) => {
    if (url.length === 0) return url;
    const isAlreadyHttp = url.includes('http');

    return isAlreadyHttp ? url : `https://${url}`;
  };

  const { valuesHash } = data;
  const languageList = valuesHash[languageExpression] || [];
  const locale = data.locale || 'en';
  const answers: any = value || {};
  const languagesToItterate =
    typeof languageList === 'function' ? languageList() : languageList;

  const onBlur = (e: any) => {
    const lang = e.target.id;

    const valueToSave =
      validationType === 'url'
        ? formatURL(e.target.value.trim())
        : e.target.value.trim();

    if (validationType === 'url') {
      e.target.value = valueToSave;
    }

    answers[lang] = valueToSave;
    valuesHash[`${name}_${lang}`] = valueToSave;

    question.value = answers;
  };

  const clearError = (lang: string) => {
    const err = document.querySelector(`#${lang}-error`);

    if (err) {
      // @ts-ignore
      err.style.display = 'none';
    }
  };

  survey.onValidateQuestion.add((_s: any, options: any) => {
    const { name, value } = options;
    const existingErrors: any = [];

    if (name === question.name) {
      languagesToItterate.forEach((lang: string) => {
        const { isRequired } = question;
        if (!value) return;

        const valueToTest = value[lang];

        if (isRequired && !valueToTest) {
          options.error = requiredTextEroor;
          return;
        }

        if (!valueToTest) return;

        const isValid = validators[validationType](valueToTest);

        if (!isValid) {
          const err = document.querySelector(`#${lang}-error`);
          if (err) {
            // @ts-ignore
            err.style.display = 'flex';
            existingErrors.push(lang);
          }
        }
      });

      if (existingErrors.length > 0) {
        options.error = validationErrorText;
      }
    }
  });

  const toolbar = {
    options: [
      'inline',
      'fontSize',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'emoji',
    ],
  };

  return (
    <div className="translatable-container">
      {languagesToItterate &&
        languagesToItterate.map((lang: string) => {
          const translatedLang = translateLanguage(lang, locale);
          const existingVal = value && value[lang];

          //EDITOR
          const contentBlock = htmlToDraft(existingVal || '');
          const contentState = Draft.ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = Draft.EditorState.createWithContent(contentState);

          return (
            <label key={lang} htmlFor={lang}>
              <p>{`${genericText} ${translatedLang}`}</p>
              {inputType === 'single' && (
                <input
                  className="inputtext"
                  defaultValue={existingVal || ''}
                  id={lang}
                  type="text"
                  onBlur={onBlur}
                  placeholder={placeholderText || ''}
                  onChange={() => clearError(lang)}
                />
              )}
              {inputType === 'multiline' && (
                <textarea
                  className="inputtext"
                  defaultValue={existingVal || ''}
                  id={lang}
                  onBlur={onBlur}
                  placeholder={placeholderText || ''}
                  onChange={() => clearError(lang)}
                  rows={5}
                />
              )}
              {inputType === 'editor' && (
                <div className="translatable-container__editor">
                  <Editor
                    toolbar={toolbar}
                    defaultEditorState={editorState}
                    // @ts-ignore
                    onBlur={(_event, editorState) => {
                      const state = editorState.getCurrentContent();
                      const raw = Draft.convertToRaw(state);

                      const html = draftToHtml(raw);
                      answers[lang] = html;
                      valuesHash[`${name}_${lang}`] = html;

                      question.value = answers;
                    }}
                  />
                </div>
              )}
              <div
                style={{ display: 'none' }}
                className="translatable-container__error"
                id={`${lang}-error`}
              >
                <img
                  src={warningIcon}
                  className="translatable-container__error__icon"
                  alt="warn"
                />
                <span className="translatable-container__error__text">
                  {validationErrorFieldText}
                </span>
              </div>
            </label>
          );
        })}
    </div>
  );
};
