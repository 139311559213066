import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { SurveyInstance } from '@deeptrue-frontend/ui/build/survey';
import queryString from 'query-string';
import styled from 'styled-components/macro';
import Sidebar from './sidebar';
import CompletePage from './completePage';
import TranslatableInput from '../Survey/Widgets/TranslatableInput/Base';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .tooltip__container__text {
    margin-left: 1.5rem;
    ul {
      list-style-type: disc;
      margin-left: 1.5rem;
    }
    p {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
`;

interface Props {
  json: any;
  onComplete: ({ name, email, ...rest }, model: any) => void;
  isProcessing: boolean;
  initialValues: any;
  validateOrganizationName?: (params: any) => void;
  orgCodename?: string;
  insights: any;
}

const PRISMIC_LANGS = {
  en: 'en-gb',
  de: 'de-de',
  fr: 'fr-fr',
  it: 'it-it',
};

const Briefing = ({
  json,
  onComplete,
  initialValues,
  isProcessing,
  validateOrganizationName,
  orgCodename,
  insights,
}: Props) => {
  const { locale } = useIntl();
  const { briefing } = queryString.parse(window.location.search);

  const onCurrentPageChanged = (surveyData: any) => {
    const { currentPageNo } = surveyData;
    window.history.replaceState(
      '',
      '',
      `?briefing=${briefing}&page=${currentPageNo}`
    );
  };

  return isProcessing ? (
    <CompletePage />
  ) : (
    <Container>
      <SurveyInstance
        json={json}
        onComplete={(data: any, model: any) => onComplete(data, model)}
        onCurrentPageChanged={onCurrentPageChanged}
        fixedNavigation={false}
        isSinglePage={false}
        showQuestionNumbers="off"
        validateOrganizationName={validateOrganizationName}
        initialValues={initialValues}
        showProgressBar="top"
        locale={locale}
        showCompletePage={false}
        summaryComponent={
          <Sidebar insights={insights} orgCodename={orgCodename} />
        }
        summaryClass={{
          height: '100%',
        }}
        externalWidgets={[TranslatableInput]}
      />
    </Container>
  );
};

export default Briefing;
