import React from 'react';
import { ThankYou, Typography } from '@deeptrue-frontend/ui';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

const CompleteContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 528px;
  margin: 1rem auto;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  margin: 4rem 0;
  text-align: center;
  height: 218px;
`;

const LoadingTitle = styled(Typography)`
  font-weight: 600;
`;

const Loader = styled.div`
  width: 38px;
  height: 38px;
  margin-top: 2rem;
  border-width: 3.5px;
  margin-bottom: 0.875rem;
`;

const CompletePage = () => {
  return (
    <CompleteContainer>
      <ThankYou
        title={<FormattedMessage id="briefing.finished.title" />}
        description={<FormattedMessage id="briefing.finished.description" />}
      />
      <LoadingContainer>
        <Loader className="m-loader-container__loader" />

        <LoadingTitle mb="1rem" variant="copy1">
          <FormattedMessage id="briefing.finished.loading" />
        </LoadingTitle>
        <Typography variant="copy1">
          <FormattedMessage id="briefing.finished.redirect" />
        </Typography>
      </LoadingContainer>
    </CompleteContainer>
  );
};

export default CompletePage;
